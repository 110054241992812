(function() {
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselStringInput', {
            controller: aflModalCarouselStringInputController,
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-string-input/afl-modal-carousel-string-input.html',
            bindings: {
                group: '=',
                editable: '=',
                fieldDescription: '=',
                model: '=',
                mode: '=',
                fieldName: '=',
                fullDotName: '=',
                required: '=',
                visible: '=',
                fieldChangeEventHandler: '&',
                parent: '='
            }
        });

    function aflModalCarouselStringInputController() {
        var $ctrl = this;

        $ctrl.setVisibility = function setVisibility() {
            if (typeof $ctrl.visible !== "undefined") {
                $ctrl.isVisible = !!$ctrl.visible;
            } else {
                $ctrl.isVisible = $ctrl.fieldDescription.type !== 'hidden'
            }
        };

        (function init(){
            $ctrl.setVisibility();
        })();
    }
})();
